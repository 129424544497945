import React from 'react';
import {View, StyleSheet} from 'react-native';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import {navigateTo} from '@/utils';
import {
  facebookImage,
  telegramImage,
  whatsappImage,
  xImage,
} from '../proxy.variable';
import LazyImage from '@basicComponents/image';
import globalStore from '@services/global.state';

interface IProps {
  code: string;
  invateText: string;
}

const ProxyShare = (props: IProps) => {
  const {code, invateText} = props;
  const urlPattern = /https?:\/\/[^\s]+/;
  const inviteUrl = invateText.match(urlPattern)?.[0] + '?code=' + code;
  const inviteContent = invateText.replace(urlPattern, '').trim();
  const goWhatsApp = () => {
    navigateTo(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        inviteContent + ' ' + inviteUrl,
      )}`,
    );
  };

  const goTelegram = () => {
    navigateTo(
      `https://t.me/share/url?text=${encodeURIComponent(
        inviteContent,
      )}&url=${encodeURIComponent(inviteUrl)}`,
    );
  };

  const goFacebook = () => {
    navigateTo(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        inviteUrl,
      )}&quote=${encodeURIComponent(inviteContent)}`,
    );
  };

  const goX = () => {
    navigateTo(
      `https://x.com/intent/post?text=${encodeURIComponent(
        inviteContent,
      )}&url=${encodeURIComponent(inviteUrl)}`,
    );
  };

  return (
    <View style={styles.container}>
      <Text style={styles.text}>Share On Social Media</Text>
      <NativeTouchableOpacity onPress={goFacebook}>
        <LazyImage width={32} height={32} imageUrl={facebookImage} />
      </NativeTouchableOpacity>
      <NativeTouchableOpacity onPress={goTelegram}>
        <LazyImage width={32} height={32} imageUrl={telegramImage} />
      </NativeTouchableOpacity>
      <NativeTouchableOpacity onPress={goWhatsApp}>
        <LazyImage width={32} height={32} imageUrl={whatsappImage} />
      </NativeTouchableOpacity>
      <NativeTouchableOpacity onPress={goX}>
        <LazyImage width={32} height={32} imageUrl={xImage} />
      </NativeTouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#ffffff',
    paddingTop: 12,
    paddingHorizontal: 12,
    paddingBottom: 24,
    position: 'absolute',
    bottom: globalStore.isWeb ? 56 : 80,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  text: {
    fontSize: 16,
    fontWeight: '700',
    color: '#535353',
  },
});

export default ProxyShare;
